<svelte:options tag="fds-input-textarea" />

<script>
    import { onMount, beforeUpdate } from "svelte";
    import { get_current_component } from "svelte/internal";
    import { createEventDispatcher } from "svelte";
    import autoresize from "./AutoresizingTextAreaComponent/autoresize";
    import fdsHelper from "@fds-components/fds-helper";
    let path = fdsHelper.get_href();
    import {
        version as componentversion,
        name as pname,
    } from "../package.json";

    /**
     * The name of the theme
     * @type {string}
     */
    export let theme = "";
    /**
     * start number of rows
     * @type {number}
     */
    export let rows = 3;
    /**
     * Sets the status which defines a different border color
     * @type  {"default" | "error" | "warning" | "success"}
     */
    export let status = "";
    /**
     * Sets the size
     * @type  {"default" | "lg" | "sm" }
     */
    export let size = "";
    /**
     * The text field width in CSS value (e.g. 200px)
     * @type {string}
     */
    export let width = "";
    /**
     * name attributr for textarea tag
     * @type {string}
     */
    export let name = "";
    /**
     * Sets the value
     * @type {string}
     */
    export let value = "";
    /**
     *  HTML input placeholder
     * @type {string}
     */
    export let placeholder = "";
    /**
     * If true the value of input will be shown without width limitation
     * @type {boolean }
     */
    export let readonly = false;
    /**
     * Set input to grey disabled state and deactivates editing
     * @type {boolean }
     */
    export let disabled = false;
    /**
     * Shows an icon at front of input
     * @type {iconString}
     */
    export let icon_prepend = "";
    /**
     * Shows an icon at end of input
     * @type {iconString}
     * */
    export let icon_append = "";
    /**
     * determines if textarea should grow on type more text
     * @type {boolean}
     */
    export let autogrow = false;
    /**
     * method fired on icon click
     * @type {function}
     */
    export let iconclick = null;

    let ischrome = navigator.appVersion.indexOf("Chrome/") != -1;
    let isiphone = (function iOS() {
        return (
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    })();
    let host = get_current_component();
    let type = "";

    let svelteDispatch = createEventDispatcher();
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail);
        host.dispatchEvent &&
            host.dispatchEvent(new CustomEvent(name, { detail }));
    };

    let error = "";
    let iconappendclass = "iconAppend";
    let containerclass = "container";
    let ref = null;

    $: {
        if (readonly == "false") readonly = false;
        readonly = !!readonly;
        if (disabled == "false") disabled = false;
        disabled = !!disabled;
    }
    $: if (autogrow && ref) {
        autogrow = !!autogrow;
        if (autogrow === "false") autogrow = false;
        setTimeout(() => {
            autoresize(ref, rows);
        }, 500);
        //ref.dispatchEvent(new Event('input', {bubbles:true}));
    }
    $: setProps();

    onMount(() => {
        setProps();
    });

    beforeUpdate(() => {
        if (!window["fds-ui-config"]) window["fds-ui-config"] = {};
        setProps();
    });

    let prop = {};

    let iconClickHandler = (e) => {
        dispatch("iconClick", e);
        if (iconclick && iconclick === "function") {
            iconclick(e);
        }
    };

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type === "version") {
            return new Promise((resolve) => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type, pname);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    function setProps() {
        if (window["fds-ui-config"] && !window["fds-ui-config"].getTheme) {
            window["fds-ui-config"].getTheme =
                document.createElement("fds-input-text").getTheme;
        }
        if (window["fds-ui-config"] && window["fds-ui-config"].getTheme) {
            //if (!theme) theme="classic";
            prop = window["fds-ui-config"].getTheme(
                status,
                size,
                disabled,
                theme
            );
        }

        if (width) prop.width = width;

        width = prop.width;
        if (!width) width = "100%";

        if (error) console.log(error);
        prop.paddingLeft = "5px";

        if (icon_prepend) {
            prop.paddingLeft = prop.iconHeight + 15 + "px";
        }
        prop.paddingRight = "5px";

        prop.paddingTop = "7px";
        if (icon_append) {
            prop.paddingRight = prop.iconHeight + 15 + "px";
        }
        prop.resize = "both";
        if (autogrow) prop.resize = "none";
        if (iconclick) {
            prop.iconCursor = "pointer";
            containerclass = "containerflex";
            iconappendclass = "iconAppendflex";
        }
    }
    /**
     * Will be fired if value has been changed
     *
     * @event change
     */
    function handleChange(e) {
        dispatch("change", {
            value: value, //passing argument
        });
    }
    /**
     * Will be fired if textarea will be focused
     *
     * @event focus
     */

    function handleFocus(e) {
        dispatch("focus", {});
    }
    
    /**
     * Will be fired if textarea itself fires event
     *
     * @event blur
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event keypress
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event keyup
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event keydown
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event mousedown
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event mousemove
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event mouseout
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event mouseover
     */
    /**
     * Will be fired if textarea itself fires event
     *
     * @event mouseup
     */
    function handleEvent(e) {
        dispatch(e.type, {
            event: e, //passing original event
        });
    }
    /**
     * for readonly mode
     * @param text
     */
    function escapeHTML(text) {
        var map = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#039;",
        };
        return text.replace(/[&<>"']/g, function (m) {
            return map[m];
        });
    }
    function nl2br(str, is_xhtml) {
        var breakTag =
            is_xhtml || typeof is_xhtml === "undefined"
                ? "<br " + "/>"
                : "<br>";

        return (str + "").replace(
            /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
            "$1" + breakTag + "$2"
        );
    }
</script>

<div class={containerclass} style="--overflow:{prop.overflow}">
    {#if readonly === false}
        <textarea
            {name}
            {rows}
            {placeholder}
            class={prop.className}
            bind:this={ref}
            bind:value
            on:focus={(e) => handleFocus(e)}
            on:change={(e) => handleChange(e)}
            on:onblur={(e) => handleEvent(e)}
            on:onkeydown={(e) => handleEvent(e)}
            on:onkeypress={(e) => handleEvent(e)}
            on:onkeyup={(e) => handleEvent(e)}
            on:onmousedown={(e) => handleEvent(e)}
            on:onmousemove={(e) => handleEvent(e)}
            on:onmouseout={(e) => handleEvent(e)}
            on:onmouseover={(e) => handleEvent(e)}
            on:onmouseup={(e) => handleEvent(e)}
            style="--resize:{prop.resize};--round:{prop.round};--shadow: {prop.shadow};
           --width: {width};--font-size:{prop.fontSize};
           --border-color:{prop.borderColor};--background-color:{prop.backgroundColor};--focus-color:{prop.focusColor};--color:{prop.color};
           --padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight};--padding-top:{prop.paddingTop};margin-bottom:0px"
            {disabled}
            id="input"
        />

        {#if prop.theme === "underline"}<span
                class:chromeunderline={ischrome || isiphone}
                class="underline-animation"
                style="--focus-color:{prop.focusColor}"
            />{/if}
    {:else}
        <div
            class="readonly"
            style="--width:{width};--font-size:{prop.fontSize};--padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight}"
        >
            {#if !value}-{:else}{@html nl2br(escapeHTML(value))}{/if}
        </div>
    {/if}
    {#if icon_prepend}<fds-icon
            on:click={iconClickHandler}
            name={icon_prepend}
            height={prop.iconHeight}
            class="iconPrepend"
            style="--icon-cursor:{prop.iconCursor};--color:{prop.iconColor};"
        />{/if}
    {#if icon_append}<fds-icon
            on:click={iconClickHandler}
            name={icon_append}
            height={prop.iconHeight}
            class={iconappendclass}
            style="--icon-cursor:{prop.iconCursor};--color:{prop.iconColor};"
        />{/if}
</div>

<style>
    * {
        box-sizing: border-box;
    }
    .container {
        position: relative;
        height: var(--height);
        display: block;
        overflow: var(--overflow);
    }
    .containerflex {
        display: flex;
        align-items: center;
    }

    .iconPrepend {
        cursor: var(--icon-cursor, "auto");
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .iconAppendflex {
        cursor: var(--icon-cursor, "auto");
        margin-left: -30px;
        z-index: 1;
    }

    .iconAppend {
        cursor: var(--icon-cursor, "auto");
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .input {
        overflow: hidden;
        color: var(--color);
        background: var(--background-color);
        font-size: var(--font-size);
        height: var(--height);
        width: var(--width);
        padding: 0;
        border: 1px solid;
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-top);
        position: relative;
        display: inline-block;
        border-color: var(--border-color);
        border-radius: var(--round);
        box-shadow: var(--shadow);
        outline: none !important;
        font-family: inherit;
    }
    .focusDefault:focus {
        border-color: var(--focus-color);
    }
    .readonly {
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);
        font-size: var(--font-size);
        height: var(--height);
        width: var(--width);
    }
    .underline {
        border: 0;
        border-bottom: 2px solid var(--border-color);
    }

    .underline-animation {
        transition: all 0.5s;
        display: inline-block;
        bottom: 0;
        left: -100%;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--focus-color);
    }

    .chromeunderline {
        bottom: 3px;
    }

    .container > .input:focus + .underline-animation {
        left: 0;
    }
    textarea {
        resize: var(--resize, both);
    }
</style>
